<template>
  <div class="table_sty">
    <div class="froms">
      <!-- <el-form label-width="100px" :model="formLabelAlign">
   
        <el-form-item label="抄送">
          <el-select
            class="form-inp"
            v-model="formLabelAlign.List"
            filterable
            remote
            reserve-keyword
            placeholder="请输入审批人"
            :remote-method="remoteMethod_s"
            multiple
            value-key="id"
          >
            <el-option
              class="form-inp"
              v-for="item in search_Data"
              :key="item.id"
              :label="item.nickname"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form> -->

      <div class="personnel">
        <div>
          <div class="endtoend">开始</div>
          <el-form label-width="80px">
            <div v-for="(item, index) in personnel_Data" :key="index">
              <div class="isty">
                <i
                  @click="on_Addpersonnel(index)"
                  class="el-icon-circle-plus-outline"
                ></i>
              </div>
              <div class="nodes-sty">
                <div
                  :class="{ 'nodes-border': select_index === index }"
                  style="flex: 1"
                  @click="on_nodes(item, index)"
                >
                  <el-form-item :label="item.name">
                    <div>
                      {{ item.message ? item.message.nickname : "暂未选择" }}
                    </div>
                  </el-form-item>
                </div>
                <el-button @click="on_deletenode(index)" type="danger"
                  >删除</el-button
                >
              </div>
            </div>
            <div class="isty">
              <i
                @click="on_Addpersonnel('S')"
                class="el-icon-circle-plus-outline"
              ></i>
            </div>
          </el-form>
          <div class="endtoend" style="color: red">结束</div>
        </div>

        <div>
          <div>
            <el-divider class="title">部门</el-divider>
            <div class="section">
              <el-tree
                :default-expanded-keys="expandedKeys"
                empty-text="暂无部门"
                :data="Tree_data"
                :props="{
                  children: 'items',
                  label: 'name',
                }"
                accordion
                @node-click="handleNodeClick"
                :auto-expand-parent="true"
              >
              </el-tree>
            </div>
          </div>
        </div>

        <div class="right">
          <div v-show="department_List.length != 0">
            <el-divider class="title">部门人员</el-divider>
            <div
              class="right-div"
              v-for="item in department_List"
              :key="item.id"
            >
              <div>
                {{ item.nickname }}
              </div>

              <el-link @click="on_select(item)" type="primary">选择</el-link>
            </div>
          </div>
        </div>
      </div>

      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  usersearch,
  flowschemeSave,
  flowschemeInfo,
  usergetDepartUsers,
} from "../assets/request/api";
export default {
  name: "process_management",
  data() {
    return {
      id: "",
      type_name: "保存",
      formLabelAlign: {
        List: [],
      },
      personnel_Data: [],
      search_Data: [],
      //部门学生
      expandedKeys: [],
      Tree_data: [],
      department_List: [],
      //当前的选择
      select_index: "A",
      select_item: "",
    };
  },
  created() {
    usergetDepartUsers().then((res) => {
      this.Tree_data = res.data;
    });
  },

  methods: {
    handleNodeClick(data) {
      this.department_Data = data;
      this.department_List = data.users;
    },
    way_show(id) {
      this.id = id;
      flowschemeInfo({ id }).then((res) => {
        let { flowcontent } = res.data.info;
        if (flowcontent) {
          let { copys, nodes } = flowcontent;

          nodes.shift();
          nodes.pop();
          let From_List = [];
          nodes.map((item) => {
            From_List.push({
              message: {
                id: item.id,
                nickname: item.name,
              },
            });
          });
          console.log(From_List);
          this.personnel_Data = From_List;
          this.personnel_Data.map((item, index) => {
            item.name = `节点${index + 1}`;
          });
        }
      });
    },

    //选择修改的人员
    on_nodes(item, index) {
      this.select_index = index;
      this.select_item = item;
    },

    on_select(item) {
      this.select_item.message = item;
    },
    //添加人员
    on_Addpersonnel(index) {
      if (index == "S") {
        this.personnel_Data.push({
          message: "",
        });
      } else {
        this.personnel_Data.splice(index, 0, {
          message: "",
        });
      }
      this.personnel_Data.map((item, index) => {
        item.name = `节点${index + 1}`;
      });
      console.log(this.personnel_Data);
    },
    //删除人员
    on_deletenode(index) {
      if (index == this.select_index) {
        this.select_index = "A";
        this.select_item = "";
      }
      this.personnel_Data.splice(index, 1);
      this.personnel_Data.map((item, index) => {
        item.name = `节点${index + 1}`;
      });
    },
    //查询节点人
    remoteMethod(query, item) {
      if (query !== "") {
        usersearch({ nickname: query }).then((res) => {
          let { data } = res;
          item.search_Data = data;
        });
      } else {
        item.search_Data = [];
      }
    },
    remoteMethod_s(query) {
      console.log(query);
      if (query !== "") {
        usersearch({ nickname: query }).then((res) => {
          let { data } = res;
          this.search_Data = data;
        });
      } else {
        this, (search_Data = []);
      }
    },
    on_preserve() {
      let { personnel_Data, formLabelAlign, id } = this;
      let { List } = formLabelAlign;
  
      // if (List.length == 0) {
      //   this.$message({
      //     showClose: true,
      //     message: "未添加抄送",
      //     type: "warning",
      //   });
      //   return;
      // }
      if (personnel_Data.length == 0) {
        this.$message({
          showClose: true,
          message: "节点未添加审批人",
          type: "warning",
        });
        return;
      }
      let nodes = [];
      let is = personnel_Data.some((item) => item.message != "");
      if (!is) {
        this.$message({
          showClose: true,
          message: "请将节点填写完整",
          type: "warning",
        });
        return;
      }
      personnel_Data.map((item) => {
        nodes.push({
          type: "node",
          name: item.message.nickname,
          setInfo: {
            NodeDesignateData: {
              users: [item.message.id],
              role: [],
            },
          },
        });
      });
      nodes.unshift({
        type: "start",
        name: "开始",
      });
      nodes.push({
        type: "end",
        name: "结束",
      });
      let copys = [];
      // let List_name = [];
      // let List_users = [];
      // List.map((item) => {
      //   List_name.push(item.nickname);
      //   List_users.push(item.id);
      // });
      // let copys_name = List_name.join(",");
      copys.push({
        type: "copy",
        // name: copys_name,
        setInfo: {
          NodeDesignateData: {
            // users: List_users,
            role: [],
          },
        },
      });

      let from_Data = {};
      from_Data.nodes = nodes;
      from_Data.copys = copys;
      let flowcontent = JSON.stringify(from_Data);
      console.log(flowcontent);
      flowschemeSave({ id, flowcontent }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          let that = this;
          that.$emit("way_tableData");
          setTimeout(function () {
            that.$emit("update:refbool", false);
          }, 1000);
        }
      });
    },
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.personnel {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > div {
    border: 1px solid #f1f4f6;
    height: 400px;
    width: 32%;
    border-radius: 5px;
    overflow-y: scroll;
  }

  & > div::-webkit-scrollbar {
    display: none; /* Chrome Safari */
    scrollbar-width: none; /* Firefox */
  }
}
.el-form-item {
  margin-bottom: 0px;
}
.isty {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 5px 0;
}
.endtoend {
  margin: 10px 0;
  text-align: center;
  font-size: 20px;
  color: #4f7afd;
  font-weight: bold;
}

.Personnel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 200px;
  & > div {
    width: 45%;
  }
}

.right {
  .right-div {
    padding: 5px;
    margin: 5px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f1f4f6;
  }
}

.el-divider__text {
  color: #409eff;
}
.nodes-sty {
  display: flex;
  justify-content: space-between;
}
.nodes-border {
  border: 1px solid #409eff;
}
</style>