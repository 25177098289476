<template>
  <div>
    <div v-for="(item, index) in data_cloumn" :key="index" class="select-sty">
      <div>
        <div class="message">
          {{ item.name }}
        </div>
        <el-checkbox v-model="item.isselect" />
      </div>

      <div>
        <div class="message">提示信息：</div>
        <el-input
          placeholder="请输入提示内容"
          v-model="item.placeholder"
          clearable
        >
        </el-input>
      </div>
      <div>
        <div class="message">是否必填：</div>
        <el-checkbox v-model="item.isrules" />
      </div>
    </div>
    <div style="text-align: center">
      <el-button @click="route_go">取消</el-button>
      <el-button type="primary" @click="on_submit()">确定</el-button>
    </div>
  </div>
</template>

<script>
import { workschemefrmInfo, frmSavesave } from "../../assets/request/api";
export default {
  name: "custom_From_select",
  data() {
    return {
      id: "",
      task_code: "",
      data_cloumn: [],
    };
  },
  methods: {
    way_show(id) {
      workschemefrmInfo({ id: id }).then((res) => {
        console.log(res);
        let { data_cloumn, info } = res.data;
        let { form_code } = info;
        let formCode = JSON.parse(form_code);
        data_cloumn.map((item) => {
          return (item.isselect = false);
        });
        if (formCode) {
          data_cloumn.map((item) => {
            formCode.map((el) => {
              if (item.model == el.model) {
                item.isselect = true;
                item.isrules = el.rules.required;
                item.placeholder = el.placeholder;
              }
            });
          });
        }

        this.data_cloumn = data_cloumn;
        this.task_code = res.data.info.task_code;
      });
      this.id = id;
    },

    on_submit() {
      let { data_cloumn } = this;
      let items = data_cloumn.filter((item) => item.isselect);
      let list = [];
      items.map((item) => {
        list.push({
          type: item.type,
          label: item.name,
          model: item.model,
          placeholder: item.placeholder,
          options: item,
          rules: {
            required: item.isrules,
            message: item.placeholder,
          },
        });
      });
      let From_Data = {
        list,
        id: this.id,
        task_code: this.task_code,
      };

      frmSavesave(From_Data).then((res) => {
        if (res.code == 0) {
          this.route_go();
        }
      });
    },
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.select-sty {
  margin-bottom: 10px;
  display: flex;
  & > div {
    flex: 1;
    display: flex;
    align-items: center;
    padding-right: 20px;
  }
}
.message {
  width: 85px;
}
</style> 

