//表格处理的混入方法
let table_minin = {
  data() {
    return {
      //是否对获取表格的方法进行扩展
      is_extend: false,
      //表格数据
      table_List: [],
      TuiSwitchState: [],
      HotSwitchState: [],
      // url表格请求方法
      url: "",
      // 点击删除url方法
      delete_Url: "",
      //点击切换状态的url方法
      is_enableUrl: "",
      //参数 
      fromData: {},
      //条数
      page_size: 10,
      //页数
      page: 1,
      //总条数
      total: 0,
      //选择数组
      more_List: [],
      //点击单个
      single_Data: "",
      //点击表格日志
      show_particulars: false,
      //表格日志传递的参数
      obj: {},
      //查询下拉选择列表
      options: [],
      //获取下拉框内容的属性
      xl_attribute: "",
      //选择的时间
      timeData: [],
      //审批任务弹窗
      auditpop_show: false,
      renwupop_show: false,
      //管控时间选择
      controlDate: [],
      //统计周期结束时间的最低选择时间
      pickerOptions: {},
    };
  },
  created() { },
  methods: {
    //统计周期结束日期禁用
    DateChange(type) {
      if (!type) {
        this.formLabelAlign.control_end = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(date, that.formLabelAlign.control_begin)
        },
      };
    },

    //isfirst表示是否回到第一页
    way_tableData(isfirst) {
      if (isfirst) {
        this.page = 1;
      }
      let { page, page_size, url, fromData, xl_attribute } = this;
      fromData.page = page + "";
      fromData.page_size = page_size;
      url(fromData).then((res) => {
        console.log(res);
        let { data, total } = res.data;
        this.total = total;
        this.table_List = data;
        this.TuiSwitchState = this.table_List.map(item => item.is_recomend == 1 ? true : false)
        this.HotSwitchState = this.table_List.map(item => item.is_hot == 1 ? true : false)
        this.options = res.data[xl_attribute];
        //判断需不需要对返回数据扩展处理
        if (this.is_extend) {
          this.way_extend(res.data);
        }
      });
    },
    //分页
    way_page(val) {
      this.page = val;
      this.way_tableData();
    },
    //重置按钮
    on_reset() {
      let { fromData } = this;
      this.timeData = [];
      this.controlDate = [];
      Object.keys(fromData).forEach((key) => {
        fromData[key] = "";
      });
      if (this.$refs.Bepartment) {
        this.$refs.Bepartment.bepartment_List = [];
      }
    },
    //多选删除
    on_deleteList() {
      let { more_List } = this;
      if (more_List.length == 0) {
        this.$message.error("请选择后在进行删除");
        return;
      }
      let that = this;
      this.$confirm("是否确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let more = [];
          more_List.forEach((item) => {
            more.push(item.id);
          });
          let id = more.join(",");
          that.delete_Url({ id: id }).then((res) => {
            if (res.code == 0 || res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              that.way_tableData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //表格下标累加
    indexMethod(index) {
      index = index + 1 + (this.page - 1) * this.page_size;
      return index;
    },
    //点击审批
    on_pop_flow() {
      let { more_List } = this;
      if (more_List.length == 0) {
        this.$message({
          message: "请选择后在进行审批",
          type: "warning",
        });
        return;
      }
      let id = more_List[0].id;
      console.log(id);
      this.auditpop_show = true;
      this.$nextTick(function () {
        this.$refs.auditpop.way_show(id);
      });
    },
    //点击任务
    on_poprw_flow() {
      let { more_List } = this;
      if (more_List.length == 0) {
        this.$message({
          message: "请选择后在选择任务",
          type: "warning",
        });
        return;
      }
      let id = more_List.map(item => item.id).join(',')
      console.log(id);
      this.renwupop_show = true;
      this.$nextTick(function () {
        this.$refs.renwupop.way_show(id);
      });
    },

    //改变每页数量
    way_size(val) {
      this.page_size = val;
      this.way_tableData(true);
    },

    //点击导出exl
    export_Exl() {

      let { more_List } = this;
      if (more_List.length == 0) {
        this.$message({
          message: "请选择后再导出xlsx表格",
          type: "warning",
        });
        return;
      }
      let id = more_List.map(item => item.id).join(',')
      this.url_exl({ id }).then(res => {
        const content = res;
        const blob = new Blob([content]);
        const fileName = "风险点列表.xlsx";
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      })

    },
    //选择多个时
    way_moreselect(val) {
      this.more_List = val;
    },
    //点击单个时
    way_singleselect(val) {
      this.single_Data = val;
    },
    //点击打开详情
    on_particulars(path, id) {
      console.log(path)
      this.$router.push({
        path: path,
        query: {
          id: id,
        },
      });
    },
    //跳转指定地址
    on_addplus(path, id) {
      this.$router.push({
        path: path,
        query: {
          id: id,
        },
      });
    },
    //选择时间
    on_timeData() {
      let { timeData, fromData } = this;
      console.log(timeData);
      if (timeData == null) {
        fromData.bengin_time = "";
        fromData.end_time = "";
      } else {
        fromData.bengin_time = timeData[0];
        fromData.end_time = timeData[1];
      }
    },
    //管控选择时间
    on_controlDate() {
      let { controlDate, fromData } = this;
      if (controlDate == null) {
        fromData.control_begin = "";
        fromData.control_end = "";
      } else {
        fromData.control_begin = controlDate[0];
        fromData.control_end = controlDate[1];
      }
    },
    //点
    on_controlid(item) {
      console.log(item)
      this.department_name = item.name
      this.formLabelAlign.department_id = item.id
      this.way_tableData(true)
    },
    //切换状态
    on_is_enable(event, id) {
      let From_Date = {
        state: event,
        id: id,
      };
      this.is_enableUrl(From_Date).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },
  },
};
export default table_minin;
